export const environment = {
  production: false,
  APPUrl: "https://kml.stg.amr.kpmg.com/",
  APIUrl: "https://kmlapi.stg.amr.kpmg.com/api/",
  ClientID: "b3d18b24-2f14-4670-abbd-d56bab49af74",
  instrumentationKey: "",
  AuthorityURL: "https://login.microsoftonline.com/deff24bb-2089-4400-8c8e-f71e680378b2",
  authenticationMessage: "Auth Token expired",
  sessionTimoutMessage: "Session Timed out - Please login",
  idle_timer: 1200,
  refreshtoken_timer: 2000000,
  gridLoadValue: 100,
  handsontableLicenseKey: "c0d1f-7257d-34ce1-8422c-61f17"
};
